import React from "react";
import TeamMateCard from "./teamMateCard";

const OurTeam = () => {
  return (
    <section className="our-team-wrapper section-padding">
        <div className="container">
            <div className="row align-items-center mb-40">
                <div className="col-12 col-md-6">
                    <div className="section-title">
                        <p>Exclusive Members</p>
                        <h1>Meet Our Experience Team Members</h1>
                    </div>
                </div>
            </div>

            <div className="team-members-list row">

                <TeamMateCard classStyle={"single-team-member active"} bgImgUrl={'assets/img/team/team1.webp'} name={"Thankgod k. Maduka"} role={"CEO Abia tech hub"} />

                <TeamMateCard classStyle={"single-team-member"} bgImgUrl={'assets/img/team/team9.webp'} name={"Nwajamma Jigo"} role={"Head of marketing"} />

                <TeamMateCard classStyle={"single-team-member"} bgImgUrl={'assets/img/team/team13.webp'} name={"Lucky Ekezie"} role={"Senior Product Designer"} />

                <TeamMateCard classStyle={"single-team-member"} bgImgUrl={'assets/img/team/team10.webp'} name={"Mario Enyinnia"} role={"CTO Abia tech hub"} />

                {/* <TeamMateCard classStyle={"single-team-member"} bgImgUrl={'assets/img/team/team2.webp'} name="Chibueze Obasi" role={"Project Manager"} /> */}

                <TeamMateCard classStyle={"single-team-member"} bgImgUrl={'assets/img/team/team3.webp'} name={"Miracle Kelechi"} role={"Senior web Developer"} />

                <TeamMateCard classStyle={"single-team-member"} bgImgUrl={'assets/img/team/team4.webp'} name={"Chijioke Daniel"} role={"Full Stack web Developer"} />

                <TeamMateCard classStyle={"single-team-member"} bgImgUrl={'assets/img/team/team5.webp'} name={"Victor Jonah"} role={"Software Developer"} />

                <TeamMateCard classStyle={"single-team-member"} bgImgUrl={'assets/img/team/team6.webp'} name={"Chioma Ada"} role={"Content Creator"} />

                <TeamMateCard classStyle={"single-team-member"} bgImgUrl={'assets/img/team/team7.webp'} name={"Okeh Margret"} role={"UI/UX Designer"} />

               

            </div>
        </div>
    </section>
  );
};

export default OurTeam;